import { render } from 'react-dom';

import { RecoilRoot } from 'recoil';

import reportWebVitals from './reportWebVitals';
import App from './app';
import 'react-loading-skeleton/dist/skeleton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'tippy.js/dist/tippy.css';
import './styles/index.sass';
import ContextProvider from './config/ContextProvider';

render(
  <RecoilRoot>
    <ContextProvider>
      <App />
    </ContextProvider>
  </RecoilRoot>,
  document.getElementById('root')
);

reportWebVitals();
