import { useState, createContext, useMemo } from 'react';

import PropTypes from 'prop-types';

export const ContextState = createContext();

const initialState = { edit: false, editCase: false, hideScroll: false, caseWillBeCreated: false };

const ContextProvider = ({ children }) => {
  const [context, setContext] = useState(initialState);

  const updateContextState = (newState) => setContext(newState);

  const value = useMemo(() => ({ context, updateContextState }), [context]);

  return (
    <ContextState.Provider value={value}>
      {children}
    </ContextState.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContextProvider;