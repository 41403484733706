import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const FormSnippet = lazy(() => import('../modules/public/FormSnippet'));
const PrivateRouter = lazy(() => import('./private'));

const App: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={false}>
      <Routes>
        <Route element={<FormSnippet />} path="/form/:id" />
        <Route element={<PrivateRouter />} path="*" />
      </Routes>
    </Suspense>
  </BrowserRouter>
);

export default App;
